import { useEffect } from 'react';
import { useSnapshot } from 'valtio';
import { signalrStore2 } from '~/modules/SDK/Signalr/signalrStore2';
export const useOptionReferencePrice = (mtx) => {
    useEffect(() => {
        signalrStore2.addQuote([mtx]);
        return () => {
            signalrStore2.removeQuote([mtx]);
        };
    }, [JSON.stringify([mtx])]);
    const value = useSnapshot(signalrStore2.values.quote)[mtx];
    const bid = value?.bid ?? NaN;
    const ask = value?.ask ?? NaN;
    const price = Math.round((bid + ask) / 2);
    return price;
};
/** T盤與T+1盤 給出標的參考價格 */
// export const useOptionReferencePrice = () => {
//   const hours = dayAPI().hour()
//   const days = dayAPI().day()
//   const isWeekend = days === 0 || days === 6
//   const indexReference = hours >= 8 && hours < 15 && !isWeekend
//   const referenceSymbol = indexReference ? 'TSEA' : 'TX-1'
//   useEffect(() => {
//     signalrStore2.addQuote(referenceSymbol)
//     return () => {
//       signalrStore2.removeQuote(referenceSymbol)
//     }
//   }, [JSON.stringify(referenceSymbol)])
//   const value = signalrHooks2.useQuotes(['TSEA', 'TX-1'])
//   const indexPrice = value.map(s => s.close)[0]
//   const futuresPrice = value.map(s => s.close)[1]
//   const referencePrice = indexReference ? indexPrice : futuresPrice
//   return {
//     /** 選擇權參考價格 */
//     referencePrice: referencePrice,
//     /** 現貨價格 */
//     indexPrice: indexPrice,
//     /** 期貨價格 */
//     futuresPrice: futuresPrice,
//   }
// }
