import { proxy } from 'valtio';
import { FuiTabs2 } from '../heineken_template/components/FuiTabs2';
export const asia71178_store = proxy({
    scores: [],
    buildUp: NaN,
    buildDn: NaN,
    powerValue: NaN,
    tabs1: new FuiTabs2(['default', 'watchList']),
    optionVolumeSort: 'default',
    pageState: 'default',
});
