import { map } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useSnapshot } from 'valtio';
import { useIntervalNow } from '~/hooks/useIntervalNow';
import { signalrStore2 } from '~/modules/SDK/Signalr/signalrStore2';
import { __TYPING__ } from '~/utils/__TYPING__';
import { expectType } from '~/utils/tsd';
export const signalrHooks2 = {
    /**
     * 訂閱|解訂|取Ohlc值，語法糖
     *
     * @example
     *   function SmallPureComponent() {
     *     //
     *     // mount 時會 addQuote
     *     // unmount 時就 removeQuote
     *     // return 就是 OHLC value
     *     const tx1OhlcData = useWatchQuote('TX-1')
     *
     *     tx1OhlcData.close // type = number
     *
     *     return null
     *   }
     */
    useWatchQuote(symbol) {
        const ohlc = useSnapshot(signalrStore2.values.quote)[symbol] || null;
        useEffect(() => {
            signalrStore2.addQuote(symbol);
            return () => {
                signalrStore2.addQuote(symbol);
            };
        }, [symbol]);
        return ohlc;
    },
    /**
     * - 慢速拿 signalr 的 values
     * - 慢速觸發 re-render
     */
    useValuesThrottled(throttleMs = 1000) {
        const [values, setValues] = useState(signalrStore2.values);
        useIntervalNow(() => {
            setValues({ ...signalrStore2.values });
        }, throttleMs);
        return values;
    },
    /** 給予 string[] 返回一組報價陣列 */
    useQuotes(symbols = []) {
        const quotes = signalrHooks2.useValuesThrottled(1000).quote;
        return map(symbols, symbol => quotes[symbol]).filter(value => !!value);
    },
    /**
     * 合併你給的其它數據，合併到報價數據
     *
     * @example
     *   // ## 例如你可以用訊號牆的數據，合併出報價數據
     *
     *   const data = signalrHooks2.useMergeQuotes([{ symbol: 'TX-1', message: '盤中創高' }])
     *   // 你的數據
     *   data[0].symbol // 'TX-1'
     *   data[0].message // '盤中創高'
     *
     *   // ...報價數據
     *   data[0].close // 18169
     *   data[0].low // 18000
     */
    useMergeQuotes(data) {
        const quotes = signalrHooks2.useValuesThrottled(1000).quote;
        return useMemo(() => {
            return map(data, datum => {
                return {
                    ...datum,
                    ...quotes[datum.symbol],
                };
            });
        }, [data, quotes]);
    },
};
/* istanbul ignore next */
/* eslint-disable @typescript-eslint/ban-ts-comment */
if (__TYPING__) {
    // [test] 可以給「含有 symbol 屬性」的「物件」
    expectType(signalrHooks2.useMergeQuotes([{ symbol: true, message: '盤中創高18169' }]));
    // [test] 不能給 string[]
    expectType(
    /** @ts-expect-error 若亮紅線代表要修到不亮線 */
    signalrHooks2.useMergeQuotes(['TX-1']));
}
/* eslint-enable @typescript-eslint/ban-ts-comment */
